// Vendor
import React, { Fragment, useRef, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Form as FinalForm, Field, FormSpy } from 'react-final-form';
import createDecorator from 'final-form-focus';
import cn from 'classnames';

// Subscibe config
import config from 'config/xsolla.json';

// Components
import AppLink from 'components/AppLink';
import Fade from 'components/Animations/Fade';
import Heading from 'components/Heading';
import Form from 'components/Form';
import InputField from 'components/InputField';
import Button from 'components/Button';
import CopyClipboard from 'components/CopyClipboard';

// Actions
import { subscribeToNewsletter, clearSubscribeData } from 'state/subscribe';

// Utils
import { email as validateEmail } from 'utils/validators';

import useAnalytics from 'hooks/useAnalytics';

import styles from './styles.styl';

const focusOnError = createDecorator();

const Footer = ({ className }) => {
  const formRef = useRef();
  const initialValues = {
    email: '',
  };

  const dispatch = useDispatch();
  const subscribe = useSelector(state => state.subscribe);
  // const isServer = useSelector(state => state.responsive.isServer);
  const [fieldError, setFieldError] = useState(false);
  const subscription = useMemo(() => ({ active: true, values: true }), []);
  const currentYear = useMemo(() => new Date().getFullYear(), []);
  const isError = Boolean(subscribe.error);
  const isSubscribe = Boolean(subscribe.success);

  const { elementClick } = useAnalytics();

  /** Отправка формы.
   * Делает запись в localStorage
   *
   * @param {object} values
   */
  const onSubscribe = useCallback(
    ({ email }) => {
      if (email) {
        const validationResult = validateEmail(email);
        const isValid = !validationResult;
        if (isValid) {
          if (formRef.current) {
            setTimeout(() => {
              formRef.current.form.reset();
              formRef.current.form.resetFieldState('email');
            });
          }
          dispatch(subscribeToNewsletter(email));
          elementClick('80lv_site_main-page_subscribe-to-newsletter', 'footer');
        } else {
          setFieldError(validationResult);
        }
      } else {
        setFieldError(true);
      }
    },
    [dispatch, elementClick],
  );

  /** Очистка state формы */
  const onClear = useCallback(() => dispatch(clearSubscribeData()), [dispatch]);

  /** Слушатель FormSpy */
  const changeForm = useCallback(field => {
    if (field.values.email) setFieldError(false);
  }, []);

  return (
    <footer className={cn(styles.Footer, className)}>
      <div className={styles.Footer__content}>
        <Heading className={styles.Footer__title} onClick={onClear}>
          Subscribe
        </Heading>
        <p className={styles.Footer__subtitle}>Start receiving our weekly newsletter</p>

        <FinalForm
          ref={formRef}
          onSubmit={onSubscribe}
          decorators={[focusOnError]}
          initialValues={initialValues}
        >
          {({ handleSubmit }) => (
            <Form
              id="subscribe-form"
              className={cn(styles.Footer__form, {
                [styles.Footer__form_error]: isError,
              })}
              onSubmit={handleSubmit}
            >
              <FormSpy onChange={changeForm} subscription={subscription} />

              <label className={styles.Footer__formLabel}>
                <Field name="email">
                  {({ input, meta: { active, invalid, visited } }) => (
                    <InputField
                      {...input}
                      className={styles.Footer__formField}
                      placeholder="Email address"
                      theme="subscribe"
                      invalid={invalid}
                      active={active}
                      visited={visited}
                      error={fieldError || ''}
                    />
                  )}
                </Field>

                {(isError || fieldError) && (
                  <Fragment>
                    <p className={styles.Footer__formError}>{subscribe.error || fieldError}</p>
                    <div className={styles.Footer__formLayerError} onClick={onClear} />
                  </Fragment>
                )}

                <Fade in={isSubscribe} duration={300}>
                  <p className={styles.Footer__formLayerSuccess} onClick={onClear}>
                    Yay, thank you.
                  </p>
                </Fade>
              </label>

              {!isSubscribe && !fieldError && (
                <Button
                  type="submit"
                  className={cn(styles.Footer__formButton, {
                    [styles.Footer__formButton_hidden]: isError,
                  })}
                  theme="withShadow"
                >
                  Subscribe
                </Button>
              )}
            </Form>
          )}
        </FinalForm>

        <ul className={styles.Footer__socials}>
          {config.subscribe.socials.map(item => (
            <li key={item.id} className={styles.Footer__social}>
              <div className={styles.Footer__social_icon}>
                <img src={item.icon} alt={item.name} />
              </div>

              {item.copyLink ? (
                <CopyClipboard className={styles.Footer__socialLink} {...item} />
              ) : (
                <AppLink href={item.link} className={styles.Footer__socialLink} targetBlank>
                  {item.caption}
                </AppLink>
              )}
            </li>
          ))}
        </ul>

        <div className={styles.Footer__copyright}>
          <p className={styles.Footer__copyrightRow}>
            &#169; {currentYear}. 80 level. All rights reserved
          </p>

          <ul className={cn(styles.Footer__nav, styles.Footer__copyrightRow)}>
            <li className={styles.Footer__navItem}>
              <AppLink href="/contact-us">About & Contact us</AppLink>
            </li>
            <li className={styles.Footer__navItem}>
              <AppLink href="/privacy-policy">Privacy Policy</AppLink>
            </li>
            <li className={styles.Footer__navItem}>
              <AppLink href="/republishing-policy">Republishing policy</AppLink>
            </li>
            <li className={styles.Footer__navItem}>
              <AppLink href="/terms-of-use">Terms of use</AppLink>
            </li>
            <li className={styles.Footer__navItem}>
              <AppLink href="/disclaimer">Disclaimer</AppLink>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
};

Footer.defaultProps = {
  className: '',
};

export default Footer;
